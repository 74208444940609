
import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class Avanzado extends Vue {
    @Prop({ type: String, required: true }) readonly title!: string;
    @Prop({ type: Number, required: true }) readonly Ppercent!: number;
    @Prop({ type: Number, required: true }) readonly Pvalue!: number;
    @Prop({ type: Number, required: true }) readonly Epercent!: number;
    @Prop({ type: Number, required: true }) readonly Evalue!: number;
    @Prop({ type: String, required: true }) readonly Unit!: string;

    get Energy() {
        return this.Evalue.toLocaleString("de-DE");
    }

    get Price() {
        return this.Pvalue.toLocaleString("de-DE");
    }
}
